import { cn } from "@/utils/class-merge";
import { ComponentProps, ReactNode } from "react";
import { Spinner } from "../icons";

export interface IButtonProps extends ComponentProps<"button"> {
	children?: ReactNode;
	className?: string;
	shape?: "SQUIRCLE" | "SQUARE" | "ROUNDED";
	variant?: "PRIMARY" | "SECONDARY" | "GHOST" | "LINK";
	size?: "NORMAL" | "ICON" | "AUTO" | "ZERO";
	loading?: boolean;
	disabled?: boolean;
}

const Button = ({
	children,
	type = "button",
	variant = "PRIMARY",
	shape = "ROUNDED",
	size = "NORMAL",
	className,
	loading,
	disabled = false,
	...props
}: IButtonProps) => {
	return (
		<button
			className={cn(
				"flex items-center justify-center gap-2 text-[4vw] font-semibold transition-all duration-300 ease-linear ",
				/* Shape Declarations Styling */
				size === "NORMAL" && " w-full px-[4vw] py-[0.6vh] ",
				size === "ICON" && "min-h-[5vw] min-w-[5vw] p-[2.5vw]",
				size === "AUTO" &&
					"min-h-auto min-w-auto h-auto w-auto p-[4vw] py-[0.6vh]",
				size === "ZERO" && "m-0 min-h-fit min-w-fit p-0 ",
				/* Shape Declarations Styling */
				shape === "ROUNDED" && "rounded-full",
				shape === "SQUARE" && "rounded-none",
				shape === "SQUIRCLE" && "rounded-[8px]",
				/* Variant Declarations Styling */
				variant === "GHOST" &&
					"border border-[#d1cec0] bg-white text-[#272525]/80 hover:bg-secondary-100 disabled:border-none disabled:bg-neutral-100",
				variant === "LINK" &&
					"bg-transparent  text-secondary-600 hover:bg-transparent hover:text-secondary-500 disabled:bg-transparent",
				variant === "SECONDARY" &&
					"border border-neutral-950 bg-neutral-900 text-white hover:bg-neutral-800/80 disabled:border-neutral-600 disabled:bg-neutral-600",
				variant === "PRIMARY" &&
					"border border-primary-600 bg-primary-600 text-white hover:bg-primary-500 disabled:border-primary-200 disabled:bg-primary-200",
				className,
			)}
			type={type}
			{...props}
			disabled={loading || disabled}
		>
			{loading && <Spinner className=" w-8 text-secondary-600" />}
			{children}
		</button>
	);
};

export default Button;
